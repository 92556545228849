<template>
  <div style="width: 100%; background-color: #2d2b2b;">
      <v-card class="transparent" elevation="0">
        <v-card-text class="mx-auto m-txt-center" style="color: #f6f6f6">
          Todos os Direitos Reservados à NetSpace | Desenvolvido por DELMAWAVE
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
export default {
  name: "SectionCopy",
};
</script>

<style scoped></style>
